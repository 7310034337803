<template>
  <div class="ui-progress-bar">
    <div class="ui-progress-bar__top" v-if="label || caption">
      <div class="ui-progress-bar__top__label" v-if="label">
        {{ label }}
      </div>
      <div class="ui-progress-bar__top__caption" v-if="caption">
        {{ caption }}
      </div>
    </div>
    <div class="ui-progress-bar__line">
      <div
        class="ui-progress-bar__line__percentage"
        :class="[
          `ui-progress-bar__line__percentage--${variant}`,
          { 'ui-progress-bar__line__percentage--animated': animated },
        ]"
        :style="{ width: `${percentage}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiProgressBar',
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
    animated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-progress-bar {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    &__label {
      font-size: $progress-bar-label-font-size;
      font-weight: $progress-bar-label-font-weight;
    }

    &__caption {
      font-size: $progress-bar-caption-font-size;
    }
  }

  &__line {
    position: relative;
    border-radius: 25px;
    background-color: $progress-bar-color-bg;
    height: $progress-bar-height;

    &__percentage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transition: width $transition-duration-default $transition-effect-default;
      border-radius: 25px;
      width: 0;

      @each $name, $variant in $generic-color-variants {
        &--#{$name} {
          background-color: $variant;
        }
      }

      &--animated {
        @include wave;
      }
    }
  }
}
</style>
