<template>
  <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
  <div class="home">
    <ui-button button="cta" label="Action" />
    <ui-button button="primary" label="Action" />
    <ui-button button="secondary" label="Action" />

    <ui-button button="cta" label="Action" icon="notifications_outline" />
    <ui-button button="primary" label="Action" icon="notifications_outline" />
    <ui-button button="primary" label="Action" icon="notifications_outline" :disabled="true" />
    <ui-button button="primary" label="Action" icon="notifications_outline" :reverse="true" />
    <ui-button button="secondary" label="Action" icon="notifications_outline" />
    <ui-button button="secondary" label="Action" icon="notifications_outline" :reverse="true" />
    <ui-button button="secondary" label="Action" icon="notifications_outline" :reverse="true" :disabled="true" />

    <ui-button button="cta" label="Action" icon="notifications_outline" :icon-only="true" />
    <ui-button button="primary" label="Action" icon="notifications_outline" :icon-only="true" />
    <ui-button button="secondary" label="Action" icon="notifications_outline" :icon-only="true" />

    <ui-button button="cta" label="Login" svg="google" />
    <ui-button button="secondary" label="Action" svg="google" />
    <ui-button button="secondary" label="Login" :icon-only="true" svg="google" />
    <ui-button button="primary" label="Login" svg="facebook" :disabled="true" />

    <ui-button button="cta" label="Action" icon="notifications_outline" :icon-only="true" variant="success" />
    <ui-button button="cta" label="Action" icon="notifications_outline" :icon-only="false" variant="purple" />
    <ui-button
      button="cta"
      label="Action"
      icon="notifications_outline"
      :icon-only="false"
      variant="purple"
      :disabled="true"
    />
    <ui-button button="primary" label="Action" icon="notifications_outline" :icon-only="true" variant="success" />
    <ui-button button="primary" label="Action" icon="notifications_outline" variant="purple" />
    <ui-button
      button="primary"
      label="Action"
      icon="notifications_outline"
      :icon-only="true"
      variant="success"
      :disabled="true"
    />
    <ui-button button="secondary" label="Action" icon="notifications_outline" :icon-only="true" variant="success" />
    <ui-button
      button="secondary"
      label="Action"
      icon="notifications_outline"
      :icon-only="true"
      variant="success"
      :disabled="true"
    />

    <ui-checkbox v-model="checkbox" id="checkbox-1">Checkbox</ui-checkbox>
    <ui-checkbox v-model="checkbox" id="checkbox-2" :disabled="true">Checkbox</ui-checkbox>
    <ui-checkbox v-model="checkbox" id="checkbox-4" :reverse="true">Checkbox</ui-checkbox>

    <ui-radio value="one" id="one" v-model="radio" :disabled="true"> One </ui-radio>
    <ui-radio value="two" id="two" v-model="radio"> Two </ui-radio>
    <ui-radio value="three" id="three" v-model="radio" :disabled="true"> Three </ui-radio>
    <ui-radio value="four" id="four" v-model="radio" :reverse="true"> Four </ui-radio>
    <ui-switch id="switch" v-model="toggle"> Switch </ui-switch>
    <ui-switch id="switch-1" v-model="toggle" :disabled="true"> Switch </ui-switch>
    <ui-switch id="switch-1" v-model="toggle" :disabled="true" :standalone="true"></ui-switch>
    <span
      class="backoffice-icons"
      v-tooltip="{
        placement: 'top',
        trigger: 'hover click',
        content: 'Lorem ipsum aset illuminati',
        offset: 3,
      }"
    >
      info
    </span>
    <span
      class="backoffice-icons"
      v-tooltip="{
        placement: 'top',
        trigger: 'hover click',
        content: 'Lorem ipsum aset illuminati',
        offset: 3,
        classes: 'reverse',
      }"
    >
      info
    </span>

    <ui-button
      button="cta"
      label="Notification"
      variant="cta"
      @click="
        notification({
          text: 'Notification message',
          type: 'cta',
        })
      "
    />

    <ui-button
      button="cta"
      label="Notification"
      variant="error"
      @click="
        notification({
          text: 'Notification message',
          type: 'error',
        })
      "
    />

    <ui-button
      button="cta"
      label="Notification"
      variant="success"
      @click="
        notification({
          text: 'Notification message',
          type: 'success',
        })
      "
    />

    <ui-button
      button="cta"
      label="Notification"
      variant="warning"
      @click="
        notification({
          text: 'Notification message',
          type: 'warning',
        })
      "
    />

    <ui-button
      button="cta"
      label="Notification"
      variant="info"
      @click="
        notification({
          text: 'Notification message',
          type: 'info',
        })
      "
    />

    <ui-button
      button="cta"
      label="Notification"
      variant="info"
      @click="
        notification({
          text: 'Notification message',
          type: 'info',
        })
      "
      :disabled="true"
    />

    <ui-input label="Input label" id="input" helper="Tips can be displayed below" v-model="input" />
    <ui-input label="Input with an extremely long label" id="input-1" icon="calendar_outline" v-model="input" />
    <ui-input
      label="Input with an extremely long label"
      id="input-2"
      icon="calendar_outline"
      :reverse="true"
      v-model="input"
    />
    <ui-input
      label="Input with an extremely long label"
      id="input-2"
      icon="calendar_outline"
      :disabled="true"
      v-model="input"
    />

    <ui-textarea
      v-model="textarea"
      label="Textarea with an extremely long label"
      id="textarea-1"
      icon="calendar_outline"
      :reverse="true"
    />

    <ui-textarea
      v-model="textarea"
      label="Textarea with an extremely long label"
      id="textarea-1"
      icon="calendar_outline"
      :disabled="true"
    />
    <ui-dropdown
      v-model="dropdown"
      :options="dropdownOptions"
      placeholder="Choisir une option"
      label="name"
      track-by="id"
    />
    <ui-dropdown
      v-model="dropdown"
      :options="dropdownOptions"
      placeholder="Choisir une option"
      label="name"
      track-by="id"
      :disabled="true"
    />
    <ui-date-picker locale="fr" id="datepicker-1" v-model="datepicker" label="Date picker" />
    <ui-date-picker
      locale="fr"
      id="datepicker-2"
      v-model="datepickerRange"
      label="Date picker"
      icon="calendar_outline"
    />
    <ui-date-picker
      locale="en"
      id="datepicker-3"
      v-model="datepicker"
      label="Date picker"
      icon="calendar_outline"
      :reverse="true"
    />
    <ui-date-picker
      locale="fr"
      id="datepicker-4"
      v-model="datepickerRange"
      label="Date picker range"
      icon="calendar_outline"
      mode="range"
    />
    <ui-date-picker
      locale="fr"
      id="datepicker-5"
      v-model="datepickerRange"
      label="Date picker range"
      icon="calendar_outline"
      mode="range"
      :disabled="true"
    />

    <ui-bubble :count="7" variant="yellow" />
    <ui-bubble :count="14" />
    <ui-bubble :count="450" />

    <ui-progress-bar :percentage="50" variant="success" style="width: 300px" />
    <ui-progress-bar :percentage="73" variant="warning" :animated="true" style="width: 300px" />
    <ui-progress-bar :percentage="27" variant="yellow" label="Progress bar label" style="width: 300px" />
    <ui-progress-bar
      :percentage="94"
      variant="purple"
      label="Progress bar label"
      caption="94%"
      :animated="true"
      style="width: 300px"
    />

    <ui-tag v-model="tags" placeholder="Enter a new tag" label="name" track-by="id" @tag="addTag" @input="setTags" />
  </div>
</template>

<script>
/* eslint-disable @intlify/vue-i18n/no-raw-text */
import UiButton from '@/components/UI/Button.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiRadio from '@/components/UI/Radio.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTextarea from '@/components/UI/Textarea.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import UiBubble from '@/components/UI/Bubble.vue'
import UiProgressBar from '@/components/UI/ProgressBar.vue'
import UiTag from '@/components/UI/Tag.vue'
import { notification } from '@/utils/notification.util'

export default {
  name: 'Home',
  components: {
    UiButton,
    UiCheckbox,
    UiRadio,
    UiSwitch,
    UiInput,
    UiTextarea,
    UiDropdown,
    UiDatePicker,
    UiBubble,
    UiProgressBar,
    UiTag,
  },
  data() {
    return {
      input: 'input value',
      textarea: 'textarea value',
      checkbox: true,
      radio: 'one',
      toggle: false,
      notification,
      dropdown: '',
      dropdownOptions: [
        { name: 'Choice 1', id: 'choice-1' },
        { name: 'Choice 2', id: 'choice-2' },
        { name: 'Choice 3', id: 'choice-3' },
        { name: 'Choice 4', id: 'choice-4' },
        { name: 'Choice 5', id: 'choice-5' },
      ],
      datepicker: '',
      datepickerRange: {
        start: '',
        end: '',
      },
      tags: ['Tag 1', 'Tag 2', 'Tag 3'],
    }
  },
  methods: {
    addTag(tag) {
      this.tags.push(tag)
    },
    setTags(tags) {
      this.tags = tags
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: $gutter-desktop;

  > * {
    margin: 16px;
  }
}
</style>
